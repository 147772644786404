import KitchenConfig from "./products/kitchenaid";

// Use this form if there are no custom elements - empty div
import Null from "./products/null";

const ProductList = {
  1: {
    type: "3D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/quickdemo/electra/electra-thumb.png",
    threekit: "d8d596d8-1eed-4162-97ea-d46d2ad22d9f",
    name: "Electra Sedan",
    scene: "8d6a9673-191c-42f2-9db3-98f3a27a1c57",
    initialConfig: {},
    showAR: true,
    showConfig: true,
    brand: "Automotive",
    form: Null,
  },
  
};
export { ProductList };
